import React, { Dispatch, SetStateAction } from 'react';

import { FetchResult, useMutation } from '@apollo/react-hooks';
import { CREATE_CUSTOM_FIELD } from '@/graphql/mutations/createCustomField';

import { Formik } from 'formik';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { CUSTOM_FIELD_TYPES } from '@/constants';

import { IFileCustomFieldFormValue } from '@/types/onboardingValues';
import { IForToggle } from '@/types/customField';

import { AddYesNoToggleCustomFieldForm } from './form';
import {
  StyledFormContainer,
  StyledContainer,
} from '../styled';

interface IProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

const addCustomFieldInitialValues = {
  label: '',
  description: '',
  required: false,
  yesToggle: {
    type: null,
    allowMultipleUploads: false,
    description: '',
  },
  noToggle: {
    type: null,
    allowMultipleUploads: false,
    description: '',
  },
  example: {
    checked: null,
    text: null,
    files: null
  }
};

export interface IAddYesNoToggleCustomFieldValues {
  label: string,
  description: string,
  required: boolean,
  yesToggle: IForToggle,
  noToggle: IForToggle,
  example: {
    checked: boolean | null,
    files: IFileCustomFieldFormValue[] | null,
    text: string | null
  },
}

export const AddYesNoToggleCustomField = ({ setIsOpen, onSuccess }: IProps) => {

  const [createCustomField] = useMutation(CREATE_CUSTOM_FIELD);

  const validationShape = {
    label: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    description: Yup.string(),
    required: Yup.boolean(),
  };

  const customFieldValidation = Yup.object().shape(validationShape);

  const handleSubmit = async (data: IAddYesNoToggleCustomFieldValues) => {
    const {
      label,
      description,
      yesToggle,
      noToggle,
      required
    } = data;

    const variables = {
      type: CUSTOM_FIELD_TYPES.TOGGLE,
      label,
      description,
      required,
      yesToggle: {
        type: yesToggle.type,
        allowMultipleUploads: yesToggle.allowMultipleUploads || false,
        description: yesToggle.description,
      },
      noToggle: {
        type: noToggle.type,
        allowMultipleUploads: noToggle.allowMultipleUploads || false,
        description: noToggle.description,
      },
    };

    createCustomField({ variables })
      .then((response) => {
        showToast({
          title: 'Field Created',
          description: `Successfully created the ${label} field`,
          type: toast.TYPE.SUCCESS,
        });
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Error',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  return (
    <StyledContainer>
      <StyledFormContainer>
        <Formik
          onSubmit={handleSubmit}
          initialValues={addCustomFieldInitialValues}
          validationSchema={customFieldValidation}
          validateOnChange
          enableReinitialize
        >
          <AddYesNoToggleCustomFieldForm />
        </Formik>
      </StyledFormContainer>
    </StyledContainer>
  )
};

import React, { Dispatch, SetStateAction, useRef } from 'react';

import { ListOption } from '@/components/ListOption';

import { VIEWS } from '.';
import {
  StyledContainer,
  StyledOptionsContainer,
} from './styled';

import { ReactComponent as ScrollArrow } from '@/assets/arrow_scroll.svg';
import { useOptions } from './useOptions';
import { useScroll } from '../../utils/useScroll';

interface IProps {
  setActiveView: Dispatch<SetStateAction<keyof typeof VIEWS>>,
}

export const CustomFieldTypes = ({ setActiveView }: IProps) => {
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const {
    isScrollArrowUp,
    handleScroll,
    handleScrollArrowClick,
  } = useScroll(optionsContainerRef);

  const options = useOptions({ setActiveView })

  return (
    <StyledContainer>
      <StyledOptionsContainer ref={optionsContainerRef} onScroll={handleScroll}>
        {options.map((option) => (
          <ListOption
            key={option.title}
            title={option.title}
            description={option.description}
            handleOptionClick={option.handleOptionClick}
          />
        ))}
        <ScrollArrow
          isScrollArrowUp={isScrollArrowUp}
          handleScrollArrowClick={handleScrollArrowClick}
        />
      </StyledOptionsContainer>
    </StyledContainer>
  )
};

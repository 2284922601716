import gql from 'graphql-tag';

export const UPDATE_CUSTOM_FIELD = gql`
  mutation UpdateCustomField(
    $customFieldId: ID!
    $customFieldAttrs: CustomFieldUpdateInput!
  ) {
    updateCustomField(customFieldId: $customFieldId, customFieldAttrs: $customFieldAttrs) {
      id
    }
  }
`;

import React, {useState} from 'react';

import { get, getOr } from 'lodash/fp';
import { InitialAvatar } from './Avatar';

import { Flex } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import { H3, H4 } from '@/components/designSystem/Typography';
import { formatDateToTimeAgo } from '@/utils/date';
import { PDFDocumentModal } from '@/components/PDFDocumentCard/PDFDocumentModal';

import {
  StyledActivityContainer,
  StyledActivityLine,
  StyledIconWrapper,
  StyledIconContainer,
  StyledMessageWrapper,
  StyledTimeWrapper,
  StyledBox,
  StyledAgreementBox,
  StyledActivityList,
} from './styled';

interface IActivity {
  type: 'email' | 'application_started' | 'agreement_signed' | 'application_updated' | 'application_status_updated';
  actor: any,
  data: any,
  insertedAt: string
}

interface IProps {
  activities: any[],
}

interface IActivityItemProps {
  item: IActivity,
  index: number
}

const DiffField = (({diff} : any) => (
  <div>
    {diff.field && <div style={{fontWeight: 700}}>{diff.field}:</div>}
    {diff.removed && <div style={{textDecoration: "line-through", color: "rgb(156 163 175)"}}>{diff.removed}</div>}
    <div>{diff.added}</div>
  </div>

));

const ActivityItem = (props: IActivityItemProps) => {
  const {item} = props;

  const ActivityApplicationStarted = ({_item}: any) =>  <></>

  const ActivityAgreementSigned = ({item}: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const file = get('data.file', item);

    return(
      <StyledAgreementBox>
        <H3 bold>Agreement signed</H3>
        {file && (
        <>
          <Button onClick={() => setIsOpen(true)} secondary>Preview</Button>
          <PDFDocumentModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            file={{...file, name: file.original_filename, signedUrl: file.signed_url}}
            title={file.original_filename}
          />
        </>
        )}
      </StyledAgreementBox>
    )
  } 

  const ActivityApplicationUpdated = ({item}: any) => {
    const diff = item.data.diff;
    const step = getOr(false, "data.step", item);
    return (
      <StyledBox>
        {!!step && <strong>{step}</strong>}
        {diff.eq && diff.eq.map((item : any) => <DiffField diff={item} />)}
        {diff.del && diff.del.map((item : any) => (
          <div>
            <div style={{fontWeight: 700, color: "rgb(156 163 175)", textDecoration: "line-through"}}>{item.field}</div>
            <div style={{color: "rgb(156 163 175)", textDecoration: "line-through"}}>{item.value}</div>
          </div>
        ))}
        {diff.ins && diff.ins.map((item : any) => (
          <div>
            <div style={{fontWeight: 700}}>{item.field}</div>
            <div>{item.value}</div>
          </div>
        ))}
        <DiffField diff={diff} />
      </StyledBox>
    )
  }

  const ActivityApplicationStatusUpdated = ({item}: any) => {
    const data = item.data;
    const status = getOr('default', 'status', data);
    const map : any = {
      'approved': (
        <StyledBox style={{width: "25%"}}>
          <table style={{width: "100%"}}>
          <tr>
            <td style={{fontWeight: "bold"}}>Status:</td>
            <td>approved</td>
          </tr>
          {data.credit_limit && (
            <tr>
              <td style={{fontWeight: "bold"}}>Credit limit:</td>
              <td>{data.credit_limit}</td>
            </tr>
          )}
          {data.credit_terms && (
            <tr>
              <td style={{fontWeight: "bold"}}>Credit term:</td>
              <td>{data.credit_terms}</td>
            </tr>
          )}
          </table>
          {data.comment && <div style={{paddingTop: "1rem"}}>{data.comment}</div>}
        </StyledBox>
      ),
      'default': (
        <StyledBox>
          <span><strong>Status:</strong> {status}</span>

          {data.comment && <div style={{paddingTop: "1rem"}}>{data.comment}</div>}
        </StyledBox>
      )
    }
    return getOr(map['default'], status, map);
  }

  const ProviderAddInternalComment = ({item}: any) => {
    return (
      <StyledBox>
        {item.data.comment}
      </StyledBox>
    )
  }

  const ActivityEmail = ({item}: any) => {
    return (
      <StyledBox>
        Mailer sent to {item.data.to}
      </StyledBox>
    )
  }

  // Note: Icons are taken from https://heroicons.com/
  const mappings: {[index: string]: any} = {
    application_started: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>started an application</b></span>,
      component: <ActivityApplicationStarted item={item} />
    },
    agreement_signed: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>signed and submitted an application</b></span>,
      component: <ActivityAgreementSigned item={item} />
    },
    application_updated: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>submitted updated information</b></span>,
      component: <ActivityApplicationUpdated item={item} />
    },
    application_status_updated: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>updated application status</b></span>,
      component: <ActivityApplicationStatusUpdated item={item} />
    },
    provider_add_internal_comment: {
      avatar: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
      </svg>),
      message: <span>{item.actor.user_name} from {item.actor.company_name}</span>,
      component: <ProviderAddInternalComment item={item} />
    },
    email: {
      avatar: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z" />
        </svg>),
      message: <span>{item.actor.user_name} sent a {item.data.action_identfier} email to {item.data.to}</span>,
      component: <></>
    },
    provider_assignment: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>assigned</b> the application to <InitialAvatar name={item.data.assigned_to} innerContent={true} />{item.data.assigned_to}</span>,
      component: <></>
      // (
      //   <StyledBox>
      //     {item.data.assignment_note}
      //   </StyledBox>
      // )
    }
  }
  const defaultAvatar = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
    </svg>);
  return (
    <Flex direction='column' style={{paddingBottom: '1.5rem' }}>
      <StyledActivityLine />
      <Flex direction='row' align='flex-start' position='relative'>
        <StyledIconWrapper>
          <StyledIconContainer>
            {getOr(defaultAvatar, `${item.type}.avatar`, mappings)}
          </StyledIconContainer>
        </StyledIconWrapper>
        <StyledMessageWrapper>
          <Flex direction='row' justify='space-between' align='center'>
            <H4>{get(`${item.type}.message`, mappings)}</H4>
            <StyledTimeWrapper>{formatDateToTimeAgo(item.insertedAt)}</StyledTimeWrapper>
          </Flex>
          { get(`${item.type}.component`, mappings)}
        </StyledMessageWrapper>
      </Flex>
    </Flex>
  )
}

export const Activity = ({ activities }: IProps) => {
  return (
    <StyledActivityList>
      {activities.map((activityItem : any, activityItemIdx : any) => (
        <li key={activityItemIdx}>
          <StyledActivityContainer>
            <ActivityItem item={activityItem} index={activityItemIdx} />
          </StyledActivityContainer>
        </li>
      ))}
    </StyledActivityList>
  )};

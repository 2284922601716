import React from 'react';
import { ErrorMessage, useField } from 'formik';

import { FieldLabel } from './FieldLabel';
import {
  StyledFieldContainer,
  StyledSelect,
  StyledCreatableSelect,
  StyledErrorText,
  StyledDescriptionText,
} from './styled';
import { ILabelValue } from '@/types/field';
import { Caption } from '../Typography';

interface IProps {
  name: string,
  label?: string,
  options?: ILabelValue[],
  required?: boolean,
  creatable?: boolean,
  disabled?: boolean,
  defaultValue?: string,
  onFocus?: () => void,
  description?: string,
}

export const SelectField = ({
  required = false, name, label, options, disabled = false, creatable, defaultValue, description, onFocus
}: IProps) => {

  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { value } = field;
  const { setValue } = helpers;

  // Functionality for creatable select

  const SelectComponent = creatable ? StyledCreatableSelect : StyledSelect;

  const formatCreateLabel = (createdValue: string) => (createdValue
    ? <Caption bold>{`Hit "Enter" to add "${createdValue}"`}</Caption>
    : <Caption bold>Type to add your own...</Caption>);

  const extraOptions = creatable
    ? {
      createOptionPosition: 'last',
      isValidNewOption: () => true,
      formatCreateLabel,
    }
    : {};

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <SelectComponent
        id={name}
        name={name}
        options={options}
        classNamePrefix='Select'
        value={options ? options.find((option) => option.value === value) : undefined}
        onChange={(option: ILabelValue) => setValue(option.value)}
        defaultValue={options ? options.find((option) => option.value === defaultValue) : undefined}
        isDisabled={disabled}
        onMenuOpen={onFocus}
        error={showError}
        {...extraOptions}
      />
      {description && <StyledDescriptionText>{description}</StyledDescriptionText>}
      { showError &&
        <StyledErrorText>
          { showError ? <ErrorMessage name={name} /> : false }
        </StyledErrorText>
      }
    </StyledFieldContainer>
  );
};

import React from 'react';

import { useField, ErrorMessage } from 'formik';

import { StyledErrorText, StyledFieldContainer } from './styled';
import { Checkbox } from './Checkbox';

interface IProps {
  name: string,
  children?: React.ReactNode,
  disabled?: boolean,
  required?: boolean,
}

export const CheckboxField = ({
  name,
  children,
  disabled = false,
  required = false
}: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { value } = field;
  const { setValue } = helpers;

  return (
    <StyledFieldContainer>
      <Checkbox
        checked={value}
        onChange={() => setValue(!value)}
        isError={showError}
        disabled={disabled}
        required={required}
      >
        {children}
      </Checkbox>
      {showError &&
        <StyledErrorText>
          { (showError) ? <ErrorMessage name={name} /> : false }
        </StyledErrorText>
      }
    </StyledFieldContainer>
  )
};

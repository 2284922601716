import gql from 'graphql-tag';

export const CREATE_CUSTOM_FIELD = gql`
  mutation CreateCustomField(
    $type: String!,
    $label: String!,
    $description: String,
    $required: Boolean,
    $allowMultipleUploads: Boolean,
    $contact: Contact,
    $yesToggle: ForToggle,
    $noToggle: ForToggle,
    $options: [String]
  ) {
    createCustomField(
      type: $type,
      label: $label,
      description: $description,
      required: $required,
      allowMultipleUploads: $allowMultipleUploads,
      contact: $contact,
      options: $options,
      yesToggle: $yesToggle
      noToggle: $noToggle
    ) {
      id
    }
  }
`;

import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';

import { PrivateRoute } from '@/routes/Routes';

import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { useQuery } from '@apollo/react-hooks';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';

import { IUserCompanyRole } from '@/types/companyUser';

import { TabbedNavContainer } from '@/containers/TabbedNavContainer';

import { CurrentUserContext } from '@/providers/CurrentUser';

import { CreditApplicationCustomerInformation } from './CustomerInformation';
import { CreditApplicationCustomizableFields } from './CustomizableFields';
import { CreditApplicationTermsAndConditions } from './TermsAndConditions';
import { CreditApplicationDesignAndBrand } from './DesignAndBrand';
import { CreditApplicationSettings } from './Settings';

export const ApplicationTemplate = () => {
  const [companyId] = useLocalStorage('company-id');

  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const currentUserData = useContext(CurrentUserContext);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData);
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId),
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId)
  )
  // const isAdmin = get('isAdmin', currentUserCompanyRole[0])
  const changeTemplate = get('role.permissions.changeTemplate', currentUserCompanyRole[0]) || false;

  let navigationOptions = [
    { title: 'Settings', path: '/dashboard/application/settings', Component: CreditApplicationSettings },
  ];

  let to = '/dashboard/application/settings';

  const permitNavigation = [
    { title: 'Customer Information', path: '/dashboard/application/info', Component: CreditApplicationCustomerInformation },
    { title: 'Customizable Fields', path: '/dashboard/application/custom', Component: CreditApplicationCustomizableFields },
    { title: 'Terms and Conditions of Sale', path: '/dashboard/application/terms', Component: CreditApplicationTermsAndConditions },
    { title: 'Design and Brand', path: '/dashboard/application/brand', Component: CreditApplicationDesignAndBrand },
  ];

  if (changeTemplate) {
    navigationOptions = [...permitNavigation, ...navigationOptions]
    to = '/dashboard/application/info'
  }

  return (
    <TabbedNavContainer title='Credit Application' options={navigationOptions}>
      <Switch>
        <Redirect exact from='/dashboard/application/' to={to} />
        {
          navigationOptions.map(({ path, Component }) => <PrivateRoute key={path} path={path} exact component={Component} />)
        }
      </Switch>
    </TabbedNavContainer>
  );
};

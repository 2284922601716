import gql from 'graphql-tag';

export const GET_OWN_CUSTOM_FIELDS = gql`
  query GetOwnCustomFields {
    currentCompany {
      id
      planInfo {
        canAddField
      }
      vrCustomFields {
        id
        type
        label
        step
        name
        required
        enabled
        updatedAt
      }
      customFields {
        id
        type
        label
        description
        required
        allowMultipleUploads
        options
        contact {
          showName
          nameRequired
          showEmail
          emailRequired
          showPhone
          phoneRequired
          positionRequired
          showExtension
          extensionRequired
        }
        yesToggle {
          type
          allowMultipleUploads
          description
        }
        noToggle {
          type
          allowMultipleUploads
          description
        }
      }
    }
  }
`;
